@import 'Variables';



.arrangeMainSliderFoto {
    height: 100vh;
    width: 100%;
    /* @include breakpoint(md) {
        height: 80vh;
    } */
}
.arrangeBigTextMainSlider {
    font-size: 50px;
    color: $white;
    letter-spacing: 1px;
    @include breakpoint(xxxl) {
        font-size: 65px;
    }
    @include breakpoint(xxl) {
        font-size: 55px;
    }
    @include breakpoint(xl) {
        font-size: 45px;
    }
    @include breakpoint(lg) {
        font-size: 35px;
    }
    @include breakpoint(md) {
        font-size: 30px;
    }
}
.arrangeSmallTextMainSlider {
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 400;
    @include breakpoint(xxxl) {
        font-size: 20px;
    }
    @include breakpoint(md) {
        font-size: 12px;
    }
}
.animationSliderMain {
    animation-name: mainSlider;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;

}
@keyframes mainSlider {
    0% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1.2);
    }
}
.sliderMainDescription {
    position: absolute;
    top: 20%;
    right: 2%;
    color: $white;
    text-align: end;
    /* @include breakpoint(xxl) {
        top: 20%;
        right: 2%;
    } */
    @include breakpoint(xl) {
        top: 25%;
    }
 }
 .slideFirst,
 .slideSecond,
 .slideThird {
    &_image {
        @extend .animationSliderMain;
        @extend .arrangeMainSliderFoto;
    }
    &_big {
        @extend .arrangeBigTextMainSlider;
    }
    &_small {
        @extend .arrangeSmallTextMainSlider;
    }
 }
.slideFirst {
    &_image {
        background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ), url(../img/mainsl1.png);
        background-size: cover;
        background-position: center;

    }
}
.slideSecond {
    &_image {
        background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ), url(../img/mainsl2.JPG);
        background-size: cover;
        background-position: center;
    }
}
.slideThird {
    &_image {
        background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ), url(../img/mainsl3.jpg);
        background-size: cover;
        background-position: center;
    }
}

  /* ===============FeaturedSlider=====================  */

 .upSliderSecond {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 50px;
    
    @include breakpoint(xxl) {
        margin-top: 25px;
    }
    @include breakpoint(lg) {
        gap: 40px;
        margin-bottom: 30px;
    }
    &_first {
        font-size: 35px;
        @include breakpoint(xl) {
            font-size: 25px;
        }
        @include breakpoint(xxxl) {
            font-size: 50px;
        }
    }
    &_second {
        font-size: 35px;
        font-weight: 900;
        color: $violet;
        @include breakpoint(xl) {
            font-size: 25px;
        }
        @include breakpoint(xxxl) {
            font-size: 50px;
        }
    }
    &_third {
        margin-top: 20px;
        @include breakpoint(xl) {
            font-size: 15px;
            margin-top: 15px;
        }
        @include breakpoint(xxxl) {
            font-size: 25px;
        }
    }
}


 .sliderSecond {
    width: 320px;
   /*  border:1px solid red; */
    @include breakpoint(xl) {
        width: 96%;
        height: 300px;
        margin-left: 5px;
    }
    &_image {
        width: 96%;
        height: 400px;
        position: relative;
        overflow: hidden;
        @include breakpoint(xxxl) {
            height: 550px;
        }
        @include breakpoint(xxl) {
            width: 96%;
        }
        @include breakpoint(xl) {
            width: 96%;
            height: 300px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
     }
    &_description {
        width: 94%;
        
        h2 {
            font-size: 20px; 
        }
        p {
            margin-top: 10px;
            font-size: 14px;
            @include breakpoint(xl) {
                font-size: 12px;
            }  
        }
     }
 }
 .slick-next:before {
    width: 40px;
    height: 40px;
    background-image: url(../img/next.png);
    background-size: cover;
    background-position: center;
    /* border: 1px solid red; */
    position: absolute;
    z-index: 1;
   /*  margin-left: 30px; */
    /* background-color: rgb(55, 0, 128); */

 }
 .slick-prev:before {
    width: 40px;
    height: 40px;
    background-image: url(../img/prev.png);
    background-size: cover;
    background-position: center;
    /* border: 1px solid red; */
    position: absolute;
    z-index: 1;
    margin-left: -30px;
    /* @include breakpoint(lg) {
        left: 75%;
    }  */
 }
 .sliderSecond_image_desc {
     width: 100%;
     height: 50px;
     background-color: $violet;
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
     bottom: -50px;
     visibility: hidden;
     transition: 0.5s;
     background: linear-gradient(90deg, #002fffa2, #fcf80091,#f818188a );
     a {
         text-decoration: none;
     }
    span {
        font-weight: 700;
        color: $white;
    }
 }
 .sliderSecond_image:hover .sliderSecond_image_desc {
    bottom: 0;
    visibility: visible;
    transition: 0.5s;
 }


 /* @media(max-width:889px) {
    .slick-prev {
        left: 70%;
    }
 } */

 /* .slick-dots {
     li {
         button {
             background-color: green;
         }
     }
 } */