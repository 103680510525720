@import 'Variables';


.forDivCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.forDivColumn {
    display: flex;
    flex-direction: column;
}
.Menu {
    /* border: 1px solid ; */
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 340px;
    height: 100%;
    @include breakpoint(xl) {
        width: 300px;
    }
    @include breakpoint(lg) {
        width: 220px;
    }
    @include breakpoint(md) {
        display: none;
    }
    /* @include breakpoint(md) {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    } */
    &_mobile {
        width: 100%;
        background-color: $white;
        height: 50px;
        display: none;
        img {
            width: 20px;
            height: 20px;
        }
        /* @include breakpoint(md) {
            display: block;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        } */
    }
    &_up {
        /* border: 1px solid red; */
        height: 150px;
        width: 100%;
        background: linear-gradient(90deg, #002fff5d, #fcf8003f,#f8181841 );
        @extend .forDivCenter;
        @include breakpoint(md) {
            display: none;
        }
        &_inside {
            @extend .forDivCenter;
            height: 110px;
            width: 300px;
            @include breakpoint(lg) {
                width: 190px;
            }
            &_text {
                font-size: 90px;
                letter-spacing: -1px;
                color: $white;
                text-shadow: 0px 3px 0px #b2a98f,
                 0px 14px 10px rgba(0,0,0,0.15),
                 0px 24px 2px rgba(0,0,0,0.1),
                 0px 34px 30px rgba(0,0,0,0.1);
                 @include breakpoint(xl) {
                    font-size: 70px;
                }
                @include breakpoint(lg) {
                    font-size: 60px;
                }
            }
        } 

    }
    &_below {
        height: 100%;
        @include breakpoint(md) {
            
        }
        &_list {
            margin: 150px 0px 0px 80px;
            @extend .forDivColumn;
            gap: 20px;
            @include breakpoint(xxxl) {
                margin-top: 220px;
            }
            @include breakpoint(xxl) {
                margin-left: 100px;
                margin-top: 200px;
            }
            @include breakpoint(xl) {
                margin-left: 80px;
                margin-top: 170px;
            }
            @include breakpoint(lg) {
                margin-left: 50px;
                margin-top: 160px;
            }
            @include breakpoint(md) {
                margin: 20px 0px 0px 0px;
                align-items: center;
            }
            &_child {   
                color: $white;
                cursor: pointer;
                @include breakpoint(xxxl) {
                    font-size: 20px;
                }
                @include breakpoint(lg) {
                    font-size: 14px;
                }
            }
        }
    }
}

