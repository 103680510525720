@import 'Variables';
.galery {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 10px;
    @include breakpoint(sm) {
        
    }
    &_card {
        overflow: hidden;
        position: relative;
        height: 130px;
        border-radius: 5px;
        transition: 0.8s;
        @include breakpoint(xxxl) {
                height: 160px;
            }
            /* &:nth-child(4) {
                @include breakpoint(sm) {
                    display: none;
                }
            } */
        &:nth-child(1) {
            grid-column: 1/3;
        }
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(10)
        {
            @include breakpoint(sm) {
                grid-column: 3/5;
            }
        }
        &:nth-child(3),
        /* &:nth-child(6), */
        &:nth-child(9)
        {
            @include breakpoint(sm) {
                grid-column: 1/3;
            }
        }
        &:nth-child(6) {
            grid-column: 3/5;
            @include breakpoint(sm) {
                grid-column: 1/3;
            }
        }
        &:nth-child(7) {
            grid-column: 1/3;
            grid-row: 3/5;
            height: 270px; 
            @include breakpoint(xxxl) {
                height: 330px;
            }
            @include breakpoint(sm) {
                height: 130px;
            }
        } 
        /* &:nth-child(9) {
            @include breakpoint(sm) {
                grid-column: 1/3;
            }
        } */
        &:nth-child(11) {
            @include breakpoint(sm) {
                grid-column: 1/5;
            }
        }
        a {
            &::before {
                content: ''; 
                background-color: rgba(15, 15, 15, 0.219);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: 0.8s;
            }
            &:hover::before {
                background-color: transparent;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
         }
    }    
}



