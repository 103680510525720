* {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}
body {
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
