@import 'Variables';
.projektTabOne {
    background: rgba(231, 231, 231, 0.137);
    display: flex;
    align-items: center;
    text-align: center;
    p {
        @include breakpoint(xxxl) {
            font-size: 18px;
        }
        @include breakpoint(xl) {
            font-size: 12px;
        }
    }
}
.projectTabTwo {
    background: rgba(231, 231, 231, 0.137);
    &_direction {
       /*  border: 1px solid red; */
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 100%;
        &_picture {
            width: 25%;
            @include breakpoint(lg) {
                width: 35%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &_text {
            width: 75%;
            @include breakpoint(lg) {
                width: 65%;
            }
            h3 {
                @include breakpoint(xxxl) {
                    font-size: 25px;
                }
                @include breakpoint(xl) {
                    font-size: 15px;
                    letter-spacing: 0px;
                }
            }
            p {
                font-size: 14px;
                @include breakpoint(xxxl) {
                    font-size: 18px;
                }
                @include breakpoint(xl) {
                    font-size: 12px;
                }
                a {
                    color: $violet;
                }
            }
        }
    }
    
}
.projectTabThree {
    background: rgba(170, 167, 167, 0.87);
    /* width: 100%;
    max-width: 1065px;
    margin: auto; */
}
.projectTabThree_galery {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-gap: 10px;
    /* max-width: 1015px;
    margin: auto; */
    @include breakpoint(xl) {
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    &_card {
        position: relative;
        /* height: 150px;
        width: 195px; */
        height: 100%;
        width: 100%;
        border-radius: 5px;
        transition:visibility 0.3s linear,opacity 0.3s linear;
      /*   @include breakpoint(xxxl) {
            width: 270px;
            height: 200px;
        }
        @include breakpoint(xxl) {
            width: 170px;
            height: 150px;
        }
        @include breakpoint(xl) {
            width: 228px;
            height: 100px;
        }
        @include breakpoint(lg) {
            width: 215px;
            height: 100px;
        } */
        &:hover p {
            visibility: visible;
            transition:visibility 0.3s linear,opacity 0.3s linear;
        }
        &::before {
            content: ''; 
            background-color: rgba(0, 0, 0, 0.281);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: 0.8s;
        }
        &:hover::before {
            background-color: transparent;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.8s;
        }
        p {
            position: absolute;
            z-index: 10;
            font-size: 15px; 
            bottom: 0;
            left: 0;
            /* display: none; */
            visibility: hidden;
            transition:visibility 0.3s linear,opacity 0.3s linear;
            @include breakpoint(xxxl) {
                font-size: 20px;
            }
        }
        a {
            color:$white;
        }
        
    }
}
hr {
    margin: 10px 0px;
    @include breakpoint(xxl) {
        margin: 13px 0px;
    }
    @include breakpoint(lg) {
        margin: 20px 0px;
    }
}

/* ====================TABS Styles========================== */

.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    height: 5px;
    background: linear-gradient(90deg, #002fffa2, #fcf80091,#f818188a );  
}
.MuiButtonBase-root {
    width: 33.33%;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    max-width: 100%;
    
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    font-size:70%;
    max-width: none;
    @include breakpoint(xl) {
        font-size: 60%;
    }
}
.MuiTab-root .MuiTab-textColorPrimary .css-1q2h7u5 {
    max-width: none;
}