@import 'Variables';

.datenschutz {
    margin: 35px 35px 35px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_p {
        width: 100%;
    }
}

.impressum {
    margin: 35px 35px 35px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_p {
        width: 100%;
    }
}

.bigforimpressumdaten {
    font-size: 25px;
    font-weight: 700;
}
.middleimpressumdaten {
    font-size: 20px;
    font-weight: 900;
}