@mixin breakpoint($point) {
   @if $point == xxxl {
      @media (min-width: 1920px) { @content ; }
   }  
    @if $point == xxl {
      @media (max-width: 1400px) { @content ; }
   }
    @else if $point == xl {
      @media (max-width: 1200px) { @content ; }
   }
     @else if $point == lg {
      @media (max-width: 992px) { @content ; }
   }
    @else if $point == md {
      @media (max-width: 768px)  { @content ; }
   }
   @else if $point == sm {
      @media (max-width: 576px)  { @content ; }
   }
   @else if $point == xs {
    @media (max-width: 320px)  { @content ; }

 }
 }

 $violet:rgb(106, 90, 205);
 $white:rgb(255, 255, 255);


 /* $violet:rgb(106, 90, 205); */
 /* text-shadow: 0px 1px 4px #23430C; */
/* text-shadow: 5px 4px 11px rgb(0,0,0), 0 2px 5px rgb(0,0,0); */