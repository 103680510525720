@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Dosis:wght@600;700&family=IM+Fell+English+SC&family=Quicksand:wght@600&family=Rampart+One&display=swap');
@import 'Variables';

/* ==============RepeatClass===================== */
.footerElementsDirection {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
/* =================ColorForFooter================= */
.titleFooter {
    margin: 0px 0px 20px 0px;
    color: $white;
}
.distanceContent {
    margin: 0px 50px 0px 400px;
    @include breakpoint(xl) {
        margin: 0px 30px 0px 320px;
    }
    @include breakpoint(lg) {
        margin: 0px 30px 0px 250px;
    }
    @include breakpoint(md) {
        margin: 0px 10px 0px 10px;
    }
}
.line {
    height: 2px;
    background-color: rgba(105, 90, 205, 0.116);
    margin: 50px 0px;
    @include breakpoint(xl) {
        margin: 20px 0px;
    }
    @include breakpoint(xxl) {
        margin: 15px 0px;
    }
    @include breakpoint(lg) {
        margin: 25px 0px;
    }
}
.videoYoutube {
    margin: 35px 0px 50px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint(lg) {
        margin: 20px 0px 20px 0px;
    }
    &_big {
        font-size: 35px;
        @include breakpoint(xxxl) {
            font-size: 50px;
        }
        @include breakpoint(xl) {
            font-size: 25px;
        }
    }
    &_small {
        font-size: 35px;
        font-weight: 900;
        color: $violet;
        @include breakpoint(xxxl) {
            font-size: 50px;
        }
        @include breakpoint(xl) {
            font-size: 25px;
        }
    }
    &_video, iframe {
        margin-top: 50px;
        width: 100%;
        height: 550px;
        @include breakpoint(xxxl) {
            height: 700px;
        }
        @include breakpoint(xxl) {
            margin-top: 20px;
        }
        @include breakpoint(xl) {
            margin-top: 20px;
        }
        @include breakpoint(lg) {
            margin-top: 10px;
        }
        @include breakpoint(md) {
            height: 400px;
        }
    }
}
.footer {
    /* height: 150px; */
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: row;
    width: 100%;
    @include breakpoint(sm) {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    &_adresse, &_kontakt {
        /* border: 1px solid red; */
        text-align: center;
        @extend .footerElementsDirection;
        @include breakpoint(sm) {
            width: 100%;
        }
        h2 {
            @extend.titleFooter;
            @include breakpoint(xl) {
                font-size: 20px;
                margin-top: 10px;
            }
            @include breakpoint(sm) {
                font-size: 15px;
            }
        }
        p {
            color: $white;
            @include breakpoint(xl) {
                font-size: 12px;
            }
            @include breakpoint(lg) {
                font-size: 10px;
            }
        }
    }
    &_social {
        /* border: 1px solid red; */
        @extend.titleFooter;
        @extend.footerElementsDirection;
        @include breakpoint(sm) {
            width: 100%;
        }
        h2 {
            color: $white;
            @include breakpoint(xl) {
                font-size: 20px;
                margin-top: 10px;
            }
            @include breakpoint(sm) {
                font-size: 15px;
            }
        }
        &_icons {
            /* border: 1px solid red; */
            margin-top: 10px;
            gap: 50px;
            display: flex;
            flex-direction: row;
            @include breakpoint(xxxl) {
                gap: 100px;
            }
            @include breakpoint(lg) {
                gap: 25px;
            }
            &_face, &_wiki {
                margin-top: 15px;
                width: 50px;
                height: 50px;
                @include breakpoint(xl) {
                    width: 40px;
                    height: 40px;
                }
                @include breakpoint(sm) {
                    width: 30px;
                    height: 30px;
                }
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &_impressum  {
        /* border: 1px solid red; */
        @extend.footerElementsDirection;
        @include breakpoint(sm) {
            width: 100%;
        }
        h2 {
            @extend.titleFooter;
            @include breakpoint(xl) {
                font-size: 20px;
                margin-top: 10px;
            }
            @include breakpoint(sm) {
                font-size: 15px;
            }
        }
        a {
            color:$white;
            @include breakpoint(xl) {
                font-size: 12px;
            }
            @include breakpoint(lg) {
                font-size: 10px;
            }
        }
    }
}